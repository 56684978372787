import React from 'react';
import ImageSlider from './imageSlider';
import moment from 'moment';
import {
  UpArrowIcon,
  JobIcon,
  DownArrowIcon,
  CompletedIndicator,
  UnAssignedIcon,
  WarningIcon,
  ExceptionIndicator,
} from './svgComponents';
import './collapsible.css';
const DEAD_ANIMAL_SERVICE_TYPE = 'Dead Animal';

export default class WorkOrderJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDeadAnimalImages: false,
      selectedJob: props.selectedJob
        ? props.selectedJob
        : props.jobs && props.jobs.length === 1
        ? props.jobs[0].id
        : null,
    };
    this.onToggleJob = this.onToggleJob.bind(this);
  }

  onToggleJob(jobNumber) {
    this.setState({
      selectedJob: this.state.selectedJob === jobNumber ? null : jobNumber,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedJob: nextProps.selectedJob,
    });
  }

  fixDate(string) {
    if (!string) {
      return '';
    }
    return moment(string).parseZone().format('DD/MM/YYYY h:mm a');
  }

  convertToDate(string) {
    if (!string) {
      return '';
    }
    return moment(string).parseZone().format('DD/MM/YYYY');
  }

  toggleDeadAnimalView() {
    this.setState({
      viewDeadAnimalImages: !this.state.viewDeadAnimalImages,
    });
  }

  render() {
    const emptyDate = moment('0001-01-01', 'YYYY-MM-DD');

    return (
      <div className="collpsible-wrapper">
        {this.props.jobs?.map((job) => {
          const createdDate = moment(job.createdDate, 'YYYY-MM-DD');
          const scheduledDate = moment(job.scheduledDate, 'YYYY-MM-DD');
          const completedDate = moment(job.completedDate, 'YYYY-MM-DD');

          return (
            <div className="collapsible-item">
              <div
                className={`collapsible-item-header ${
                  job.jobNumber === this.state.selectedJob
                    ? 'collapsible-item-header-selected'
                    : null
                }`}
                onClick={() => this.onToggleJob(job.jobNumber)}>
                <label>
                  <JobIcon />
                  Job {job.jobNumber}
                </label>
                <button>
                  {job.status === 'Completed' ? (
                    <CompletedIndicator />
                  ) : job.status === 'Exception' ? (
                    <ExceptionIndicator />
                  ) : (
                    <UnAssignedIcon />
                  )}
                  <span>{job.status}</span>
                </button>
                {this.state.selectedJob !== job.jobNumber ? (
                  <DownArrowIcon />
                ) : (
                  <UpArrowIcon />
                )}
              </div>
              {this.state.selectedJob == job.jobNumber ? (
                <div className="collapsible-item-body">
                  <div className="collapsible-item-section">
                    <h4>Details</h4>

                    <div className="collapsible-card">
                      <div className="collapsible-card-item">
                        <h6>Job Service Type</h6>
                        <label>{job.jobTypeDescription}</label>
                      </div>
                    </div>

                    <div className="collapsible-card">
                      <div className="collapsible-card-item">
                        <h6>Created Date</h6>
                        <label>
                          {createdDate && !createdDate.isSame(emptyDate) ? (
                            this.fixDate(job.createdDate)
                          ) : (
                            <label className="none-text">None</label>
                          )}
                        </label>
                      </div>
                      <div className="collapsible-card-item">
                        <h6>Scheduled Date</h6>
                        <label>
                          {scheduledDate && !scheduledDate.isSame(emptyDate) ? (
                            this.convertToDate(job.scheduledDate)
                          ) : (
                            <label className="none-text">None</label>
                          )}
                        </label>
                      </div>
                      <div className="collapsible-card-item">
                        <h6>Completed Date</h6>
                        <label>
                          {(job.status === 'Completed' ||
                          job.status === 'Exception') &&
                            job.completedDate &&
                            !completedDate.isSame(emptyDate) ? (
                            this.fixDate(job.completedDate)
                          ) : (
                            <label className="none-text">None</label>
                          )}
                        </label>
                      </div>
                      <div className="collapsible-card-item">
                        <h6>Completed Reason Code</h6>
                        <label>
                          {job.reasonCode ? (
                            job.reasonCode
                          ) : (
                            <label className="none-text">None</label>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="collapsible-card">
                      <div className="collapsible-card-item">
                        <h6>Notes for Driver</h6>
                        {job.notesForDriver ? (
                          <label
                            dangerouslySetInnerHTML={{
                              __html: job.notesForDriver,
                            }}></label>
                        ) : (
                          <label className="none-text">None</label>
                        )}
                      </div>
                    </div>
                    <div className="collapsible-card">
                      <div className="collapsible-card-item">
                        <h6>Comments</h6>
                        {job.comments ? (
                          <label
                            dangerouslySetInnerHTML={{
                              __html: job.comments,
                            }}></label>
                        ) : (
                          <label className="none-text">None</label>
                        )}
                      </div>
                    </div>
                  </div>
                  {job.driverForm && Array.isArray(job.driverForm) && job.driverForm.length > 0 ? (
                    <div className="collapsible-item-section">
                      <h4>Driver Form</h4>
                      <div className="collapsible-card">
                        {job.driverForm
                          .map((item) => {
                            return (
                              <div className="collapsible-card-item">
                                <h6>{item.question}</h6>
                                {item.answer ? (
                                  <label>{item.answer}</label>
                                ) : (
                                  <label className="none-text">None</label>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : null}
                  {job.imageIds.length > 0 ? (
                    <div className="collapsible-item-section">
                      <h4>Images</h4>

                      {job.serviceType === DEAD_ANIMAL_SERVICE_TYPE &&
                      !this.state.viewDeadAnimalImages ? (
                        <div className="image-warning-box">
                          <WarningIcon />
                          <h4>Image Warning</h4>
                          <p>May contain images of dead animals</p>
                          <button
                            onClick={this.toggleDeadAnimalView.bind(this)}>
                            Reveal Images
                          </button>
                        </div>
                      ) : (
                        <div className="collapsible-card">
                          <ImageSlider
                            images={job.imageIds}
                            jobId={job.jobId}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}
