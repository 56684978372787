// @flow
import React from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import {
  SIconWrapper,
  SSingleDateWrapper,
  STodayIndicator,
  STodayLink,
} from './index.sc';
import LiveHistoricIndicator from '../LiveHistoricIndicator';
import calendarIcon from '../../assets/images/icons/calendar.svg';
import { IconArrowDown } from '../Icons/Chevron';
import { Arrow } from './PrevNextArrow';

const today = (): moment => moment();

type Props = {
  setActiveDate: Function,
  providedTimeLocalCouncil: string,
};
type State = {
  today: moment,
  pickedDate: moment,
  hasBeenGivenAccountTime: boolean,
  calendarFocused: boolean,
  isToday: boolean,
};

class PickSingleDate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      today: today(),
      pickedDate: today(),
      hasBeenGivenAccountTime: false,
      calendarFocused: false,
      isToday: true,
    };
  }

  componentDidMount() {
    const datePickerWrapper = document.getElementById('date-picker-historical-live');
    datePickerWrapper.getElementsByClassName('DateInput')[0].addEventListener('click', this.toggleCalendar);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.providedTimeLocalCouncil &&
      !this.state.hasBeenGivenAccountTime
    ) {
      const parsedDate = moment.parseZone(nextProps.providedTimeLocalCouncil);
      this.setState({
        pickedDate: parsedDate,
        today: today(),
        hasBeenGivenAccountTime: true,
        isToday: this.isToday(parsedDate)
      });
    }
  }

  componentWillUnmount() {
    const datePickerWrapper = document.getElementById('date-picker-historical-live');
    datePickerWrapper.getElementsByClassName('DateInput')[0].removeEventListener('click', this.toggleCalendar);
  }

  onDateChange = (pickedDate: moment) =>
    this.setState(
      () => ({ pickedDate, isToday: this.isToday(pickedDate) }),
      () =>
        this.props.setActiveDate(
          pickedDate.format('DD-MM-YYYY'),
          this.state.isToday,
          pickedDate,
        ),
    );

  onFocusChange = (event) => {
    if (event.focused === false) {
      this.setState(() => ({ calendarFocused: false }));
    }
  }

  getFirstQueryableDate = (): moment => {
    return moment(this.state.today)
      .subtract(6, 'months')
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
  };

  isToday = (pickedDate): boolean =>
    moment(pickedDate).isSame(this.state.today, 'day');

  isInValidDataRange = (pickedDay): boolean => {
    const pickedDayStart = pickedDay.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return moment(pickedDayStart).isBetween(
      this.getFirstQueryableDate(),
      moment(this.state.today).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      }),
      undefined,
      "[]"
    );
  }

  resetDateToToday = () => {
    this.setState({ calendarFocused: false });
    this.onDateChange(this.state.today);
  }

  toggleCalendar = () => this.setState({ calendarFocused: !this.state.calendarFocused })

  renderTodayLink = () => (
    <STodayLink
      display={this.state.calendarFocused}
      onClick={this.resetDateToToday}
    >
      <strong className={'today'}>Today</strong>
      <span className={'date'}>{this.state.today.format('MMMM Do YYYY')}</span>
    </STodayLink>
  );

  render() {
    return (
      <div id={'date-picker-historical-live'}>
        <SSingleDateWrapper
          className={`picked-today__${this.state.isToday.toString()}`}
        >
          <STodayIndicator>
            {this.state.isToday ? 'Today' : ''}
          </STodayIndicator>
          <SingleDatePicker
            small
            customInputIcon={<img alt="calendar" onClick={this.closeCalendar} src={calendarIcon} />}
            noBorder
            hideKeyboardShortcutsPanel
            daySize={31}
            date={this.state.pickedDate}
            numberOfMonths={1}
            navPrev={<Arrow previous />}
            navNext={<Arrow />}
            onDateChange={this.onDateChange}
            focused={this.state.calendarFocused}
            onFocusChange={this.onFocusChange}
            displayFormat="DD/MM/YYYY"
            isOutsideRange={(pickedDay) =>
              !this.isInValidDataRange(pickedDay)
            }
            renderCalendarInfo={this.renderTodayLink}
            readOnly
          />
          <SIconWrapper>
            <IconArrowDown open={this.state.calendarFocused} />
          </SIconWrapper>
        </SSingleDateWrapper>
       <div className='historic-indicator'>
        <LiveHistoricIndicator isLive={this.state.isToday} />
       </div>
      </div>
    );
  }
}

export default PickSingleDate;
