// @flow

import React from 'react';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Menu, Loader, Sidebar, Accordion, Dimmer } from 'semantic-ui-react';

import HEREMap from '../../components/HereMap';
import * as searchActionCreators from '../../redux/modules/search';
import * as mapActionCreators from '../../redux/modules/map';
import { hereMaps } from '../../common/options';
import AlertsTrucksFilter from '../../components/AlertsTrucksFilter';
import ImageSlideshow from '../../components/ImageSlideshow';
import TruckSidebar from '../../components/TruckSidebar';
import AddressSidebar from '../../components/AddressSidebar';
import PickSingleDate from '../../components/PickSingleDate';
import LoadingIndicator from '../../components/LoadingIndicator';
import travelPathIcon from '../../assets/images/icons/travel-path.svg';
import binIcon from '../../assets/images/icons/bin.svg';
import SearchBar from '../../components/SearchBar';
import { dayFromGivenDate } from '../../common/timeOffsetHelper';
import TrucksLoadingOverlay from './TrucksLoadingOverlay';

type Props = {
  resetSearch: Function,
  requestAddress: Function,
  requestTrucksMap: Function,
  requestAlertsMap: Function,
  requestActivityAreaMap: Function,
  resetActivityAreaMap: Function,
  requestLiftsForWastestream: Function,
  resetLiftsForWastestream: Function,
  setTruckFilter: Function,
  setAlertFilter: Function,
  requestMapLayer: Function,
  setCenterAddress: Function,
  setSearchValue: Function,
  requestAllBinImages: Function,
  receiveClickedTruck: Function,
  search: Object,
  map: Object,
  account: Object,
  location: Object,
  council: Object,
};

type Modal = {
  open: boolean,
  created: string,
  imageResources: Array<string>,
  id: string | null,
};

type ActiveEvent = {
  uuid: string | null,
  type: string | null,
};
type State = {
  isFocusOnSearch: boolean,
  mapInteractive: boolean,
  isToday: boolean,
  truckLiftsLoading: boolean,
  modal: Modal,
  mapDataToggles: Object,
  truckLifts: Array<Object>,
  activeEvent: ActiveEvent,
  activeDate: null | string,
  shouldFocusOnTruckId: string | null,
};

class LiveCollections extends React.Component<Props, State> {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleAlertFilter = this.handleAlertFilter.bind(this);
    this.handleTruckFilter = this.handleTruckFilter.bind(this);
    this.getAlertMarkers = this.getAlertMarkers.bind(this);
    this.getTruckMarkers = this.getTruckMarkers.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleThumb = this.handleThumb.bind(this);
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
    this.handleReceiveClickedTruck = this.handleReceiveClickedTruck.bind(this);
    this.setActiveEvent = this.setActiveEvent.bind(this);
    this.resetFilters = this.resetFilters.bind(this);

    this.state = {
      mapInteractive: false,
      isFocusOnSearch: false,
      isToday: true,
      ui: null,
      modal: {
        open: false,
        created: '',
        imageResources: [],
        id: null,
      },
      mapDataToggles: {
        binLifts: {
          label: 'Bin lifts',
          icon: binIcon,
          show: true,
          shouldShowDynamicData: {
            liftCount: true,
          },
        },
        travelPath: {
          label: 'Travel path',
          icon: travelPathIcon,
          show: true,
          shouldShowDynamicData: {
            liftCount: false,
          },
        },
      },
      truckLifts: [],
      truckLiftsLoading: false,
      shouldFocusOnTruckId: null,
      activeLiftsWasteStream: null,
      activeEvent: {
        uuid: null,
        type: null,
      },
      activeDate: null,
    };
  }

  componentDidMount() {
    this.loadLiveCollectionData();
    this.timeoutId = setInterval(() => {
      if(this.state.isToday) {
        //this.loadLiveCollectionData();

       this.props.requestAlertsMap(
          dayFromGivenDate(
            this.state.activeDate,
            this.state.isToday,
            this.props.council.localTime,
          ),
          this.props.council.territoryId,
        );

        this.props.requestMapLayer(this.props.council.councilName);
        this.props.requestTrucksMap(this.props.council.territoryId);
      }
    }, 1000 * 60 * 5);  
  }

  loadLiveCollectionData() {
    const {
      account,
      council,
      location,
      search,
      requestMapLayer,
      setSearchValue,
      requestAddress,
      resetSearch,
      setCenterAddress,
      requestTrucksMap,
      requestAlertsMap,
    } = this.props;

    const fromList =
      location.state !== undefined &&
      location.state.fromList &&
      search.result.id !== null;

    // check for geo fencing
    if (council.hasMapLayer) {
      requestMapLayer(council.councilName);
    }

    // came from list view
    if (fromList) {
      setSearchValue(search.result);
      requestAddress({
        ...search.result,
        ...{ range: council.range },
        territoryId: this.props.council.active.territoryId,
      });

      // reset location state
      location.state.fromList = false;

      // set default center, clear search
    } else {
      resetSearch();
      setCenterAddress(null);
    }
    // grab all markers
    requestTrucksMap(council.territoryId);

    requestAlertsMap(
      dayFromGivenDate(
        this.state.activeDate,
        this.state.isToday,
        this.props.council.localTime,
      ),
      council.territoryId,
    );
  }

  shouldComponentUpdate(nextProps) {
    const searchValueChanged =
      nextProps.search.value !== this.props.search.value;

    const searchIsFetchingChanged =
      nextProps.search.isFetching !== this.props.search.isFetching;

    const hasResults =
      nextProps.search.items.addresses.length >= 1 ||
      nextProps.search.items.trucks.length >= 1;

    if (
      this.props.search.result.id !== nextProps.search.result.id &&
      !!nextProps.search.result.id
    ) {
      this.handleResetLiftsForWastestream();
    }

    const clearSearchValue = nextProps.search.value.length === 0;

    if (hasResults || clearSearchValue) return true;

    if (searchIsFetchingChanged || searchValueChanged) {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
      this.onClearSearch();
  }

  setMapInteractive = (mapInteractive: boolean) =>
    this.setState({ mapInteractive });

  /**
   * Takes the alert filters from redux, filters the alerts
   * and returns a new array, which is passed to the map component
   *
   * @param {string[]} alerts
   * @param {string[]|string} reasons
   * @return {string[]} returns an array of reasons
   */
  getAlertMarkers(alerts, reasons, truckId?) {
    // grab all alerts
    if (
      reasons === 'all' ||
      !reasons.length ||
      (reasons.length === 1 && reasons[0] === 'all')
    ) {
      if (!truckId) {
        return alerts;
      }
      return alerts.filter(
        (item) => item.truckId && item.truckId.trim() === truckId.trim(),
      );
    }

    // return filters
    if (!truckId) {
      return alerts.filter((item) => reasons.includes(item.reason));
    }
    return alerts.filter(
      (item) =>
        reasons.includes(item.reason) && item.truckId.trim() === truckId.trim(),
    );
  }

  /**
   * Takes the truck filters from redux, filters the trucks
   * and returns a new array, which is passed to the map component
   *
   * @param {string[]} trucksList
   * @param {string[]|string} streams
   * @return {string[]} returns an array of waste streams
   */
  getTruckMarkers(trucksList, streams) {
    if (this.state.isToday === false) return [];

    let result = [];

    // grab all trucks
    if (
      streams === 'all' ||
      !streams.length ||
      (streams.length === 1 && streams[0] === 'all')
    ) {
      return trucksList;
    }

    // if other is selected, grab all those that aren't the main streams
    if (streams.includes('Other')) {
      result = trucksList.filter(
        (item) =>
          ![
            'Refuse',
            'Recycle',
            'Greenwaste',
            'Hardwaste',
            'Glass',
            'Organics',
            'Street Litter',
          ].includes(item.wasteStream),
      );
    }

    // return filters, concat 'others' if they exist
    return trucksList
      .filter((item) => streams.find((stream) => stream === item.wasteStream))
      .concat(result);
  }

  setIsFocusOnSearch = (isFocusOnSearch: boolean) => {
    this.setState((prevState) => ({
      ...prevState,
      isFocusOnSearch,
    }));
  };

  setActiveDate = (activeDate, isToday) => {
    if (this.props.map.sidebar) {
      this.setState({ activeDate, isToday }, () => {
        const dateRange = dayFromGivenDate(
          activeDate,
          this.state.isToday,
          this.props.council.localTime,
        );
        this.handleResetLiftsForWastestream();
        this.props.requestAlertsMap(dateRange, this.props.council.territoryId);

        if (!isToday) {
          this.props.requestActivityAreaMap(
            dateRange,
            this.props.council.territoryId,
          );
        }

        if (this.props.map.addressInfo.dataRadius !== null) {
          this.props.requestAddress({
            ...this.props.search.result,
            ...{ range: this.props.council.range },
            territoryId: this.props.council.territoryId,
            dayRange: dateRange,
          });
        }
      });
    } else {
      this.handleClearSearch();
      this.props.receiveClickedTruck(null);
      this.resetActiveIcon();
      this.resetBinLifts();

      this.setState({ activeDate, isToday }, () => {
        const dateRange = dayFromGivenDate(
          activeDate,
          this.state.isToday,
          this.props.council.localTime,
        );
        this.handleResetLiftsForWastestream();
        this.props.requestAlertsMap(dateRange, this.props.council.territoryId);

        if (!isToday) {
          this.props.requestActivityAreaMap(
            dateRange,
            this.props.council.territoryId,
          );
        }
      });
    }

    setTimeout(() => {
      const element = document.getElementsByClassName('alert_bubble')[0];
      if (element) element.style.display = 'none';
    });
  };

  setUIElement = (ui) => this.setState({ ui });

  setAddress(address) {
    this.props.setSearchValue(address);
  }

  setActiveEvent = (uuid, type, fullEvent) => {
    const activeEvent =
      this.state.activeEvent.uuid === uuid
        ? { uuid: null, type: null }
        : { uuid, type, ...fullEvent };

    this.setState({ activeEvent });

    return activeEvent.uuid;
  };

  setClickedTruck = (truckId, clearSearch = true) => {
    if (clearSearch) {
      this.handleClearSearch();
    }
    this.handleResetLiftsForWastestream();
    this.handleReceiveClickedTruck({ id: truckId.trim() }, '', false);
  };

  handleFocusOnTruckId = (truckId: string) => {
    if (!this.state.isToday) return;
    this.setState({ shouldFocusOnTruckId: null }, () =>
      this.setState({ shouldFocusOnTruckId: truckId }),
    );
  };

  resetActiveIcon = () =>
    this.setState({
      activeEvent: {
        uuid: null,
        type: null,
      },
    });

  handleAlertFilter(value) {
    this.props.resetActivityAreaMap();
    this.props.setAlertFilter(value);
  }

  handleTruckFilter(value) {
    this.props.resetActivityAreaMap();
    this.props.setTruckFilter(value);
  }

  handleRequestLiftsForWastestream = () => {
    if (this.state.activeLiftsWasteStream) {
      this.props.resetLiftsForWastestream();
    //  this.props.resetActivityAreaMap();
      this.props.requestLiftsForWastestream({
        date: dayFromGivenDate(
          this.state.activeDate,
          this.state.isToday,
          this.props.council.localTime,
        ),
        wasteStream: this.state.activeLiftsWasteStream,
        territoryId: this.props.council.territoryId,
      });
    }
  };
  handleBinLiftsFilter = (activeLiftsWasteStream) => {
    if (activeLiftsWasteStream.length >= 1) {
      this.setState({ activeLiftsWasteStream: activeLiftsWasteStream[0] }, () =>
        this.handleRequestLiftsForWastestream(),
      );
    } else {
      this.handleResetLiftsForWastestream();
    }
  };
  handleResetLiftsForWastestream = () => {
    this.setState({ activeLiftsWasteStream: null }, () => {
      this.props.resetLiftsForWastestream();
    });
  };

  handleSidebarToggle(value) {
    const mapDataToggles = Object.assign({}, this.state.mapDataToggles);

    mapDataToggles[value].show = !mapDataToggles[value].show;

    this.setState({ mapDataToggles });
  }

  handleClearSearch() {
    this.resetActiveIcon();
    this.props.resetSearch();
  }

  handleModalOpen(imageResources, id) {
    const filterOutEmpty = imageResources.filter((item) => item.length > 0);
    if (filterOutEmpty > 0) {
      this.props.requestAllBinImages(filterOutEmpty, id);
    }
  }

  handleModalClose() {
    this.setState((prevState) => ({
      ...prevState,
      modal: {
        open: false,
        created: '',
        imageResources: [],
        id: null,
      },
    }));
  }

  handleThumb(imageResources, created, id) {
    this.setState((prevState) => ({
      ...prevState,
      modal: {
        open: true,
        imageResources,
        created,
        id,
      },
    }));
  }

  handleReceiveClickedTruck(truck, searchText, focusOnTruck) {
    
    if(truck && truck.id && focusOnTruck !== false) {
      this.handleFocusOnTruckId(`${truck.id}`.trim());
    }
    this.props.receiveClickedTruck(truck);
    this.handleClearSearch();
    this.handleResetLiftsForWastestream();
    this.setState({
      truckLifts: [],
      truckLiftsLoading: true,
      searchText
    });
    this.resetMapDataToggles();
  }

  updateTruckLifts = (truck) => {
    this.setState({
      truckLifts: mapActionCreators.formatTruckLiftsResponse(truck),
      truckLiftsLoading: false,
    });
  };

  mapInteractive = (mapInteractive: boolean | null = null): boolean => {
    if (mapInteractive == null) {
      return this.state.mapInteractive;
    }
    this.setState({
      mapInteractive,
    });
    return this.state.mapInteractive;
  };

  resetMapDataToggles() {
    const mapDataToggles = Object.assign({}, this.state.mapDataToggles);

    mapDataToggles.binLifts.show = true;
    mapDataToggles.travelPath.show = true;
    this.setState({ mapDataToggles });
  }

  resetBinLifts() {
    const truckLifts = [];
    this.setState({ truckLifts });
  }

  resetFilters() {
    this.props.resetLiveCollectionsFilters();
  }

   resetClickedTruck = () => {
    this.props.receiveClickedTruck(null);
    this.resetActiveIcon();
    this.resetBinLifts();
  };

  onCloseSideBar = () =>{
    this.resetClickedTruck();
    this.setState({
      searchText: ''
    })
  }

  onClearSearch = () => {
    this.handleClearSearch();
    this.resetClickedTruck();
    this.setState({searchText: null})
  }

  render() {
    const { account, search, map, council } = this.props;
    const { mapDataToggles, truckLifts, isFocusOnSearch } = this.state;

    const center = {
      lat: council.lat || -25.560424,
      lng: council.lng || 134.917117,
    };

    return (
      <div className="compWithMap secondary-comp">
        <Helmet
          title="Live Collections"
          meta={[{ name: 'Live Collections', content: 'Cleanaview' }]}
        />
        <Menu secondary stackable>
          <SearchBar
            shown={map.sidebar}
            defaultValue={this.state.searchText}
            onFocus={() => this.setIsFocusOnSearch(true)}
            onBlur={() => this.setIsFocusOnSearch(false)}
            handleClearSearch={this.onClearSearch}
            handleReceiveClickedTruck={this.handleReceiveClickedTruck}
            activeDate={this.state.activeDate}
            isToday={this.state.isToday}
          />
          <PickSingleDate
            setActiveDate={this.setActiveDate}
            providedTimeLocalCouncil={this.props.council.localTime}
          />
          <Accordion className="menu-accordion computerBreakpoint menu right">
            <Accordion.Title>
              {/* <Icon className="icon-mobile-toggle" /> */}
            </Accordion.Title>
            <Accordion.Content>
              <Menu.Menu position="right">
                <AlertsTrucksFilter
                  buttonText={'Filters'}
                  handleAlertFilter={this.handleAlertFilter}
                  handleTruckFilter={this.handleTruckFilter}
                  handleBinLiftsFilter={this.handleBinLiftsFilter}
                  activeAlertFilter={map.alertFilter}
                  activeTruckFilter={map.truckFilter}
                  resetFilters={this.resetFilters}
                  activeLiftsWasteStream={this.state.activeLiftsWasteStream}
                  clickedTruck={map.clickedTruck}
                  activeDate={this.state.activeDate}
                />
              </Menu.Menu>
            </Accordion.Content>
          </Accordion>
        </Menu>

        <Sidebar.Pushable>
          <Sidebar
            animation="overlay"
            width="wide"
            visible={!!(!map.sidebar && map.clickedTruck)}>
            <div className="address-result">
              {map.clickedTruck ? (
                <TruckSidebar
                  wasteStream={map.clickedTruck.wasteStream}
                  id={map.clickedTruck.id}
                  imageUrl={map.clickedTruck.imageUrl}
                  registration={map.clickedTruck.registration}
                  created={map.clickedTruck.created}
                  handleToggle={this.handleSidebarToggle}
                  mapDataToggles={mapDataToggles}
                  truckLifts={truckLifts}
                  truckLiftsLoading={this.state.truckLiftsLoading}
                  resetClickedTruck={this.onCloseSideBar}
                  setActiveEvent={this.setActiveEvent}
                  activeEvent={this.state.activeEvent}
                  alertMarkers={this.getAlertMarkers(
                    map.alertMarkers,
                    map.alertFilter,
                    map.clickedTruck.id,
                  )}
                  handleThumb={this.handleThumb}
                  ui={this.state.ui}
                  isToday={this.state.isToday}
                  activeDate={this.state.activeDate}
                />
              ) : null}
            </div>
          </Sidebar>
          <Sidebar animation="overlay" width="wide" visible={map.sidebar}>
            <div className="address-result">
              {this.props.map.isFetching ? (
                <Loader active>Fetching Address</Loader>
              ) : (
                <div>
                  <AddressSidebar
                    addressSearchValue={search.searchedValue}
                    addressInfo={map.addressInfo}
                    alertMarkers={map.alertMarkers}
                    setClickedTruck={this.setClickedTruck}
                    setActiveEvent={this.setActiveEvent}
                    activeEvent={this.state.activeEvent}
                    handleThumb={this.handleThumb}
                  />
                </div>
              )}
            </div>
          </Sidebar>
          <Sidebar.Pushable>
            <Dimmer
              className={'dimmed-map-overlay'}
              active={
                (search.items.trucks && search.items.trucks.length >= 1 ||
                  search.items.addresses.length >= 1) &&
                isFocusOnSearch &&
                search.value.length >= 1
              }
            />
            {this.props.map.isFetchingTrucks && this.state.isToday && (
              <TrucksLoadingOverlay />
            )}
            <LoadingIndicator
              isFetching={map.isFetching}
              isFetchingTrucks={map.isFetchingTrucks}
              isFetchingWastestream={map.isFetchingWastestream}
              isFetchingActivityArea={map.isFetchingActivityArea}
            />
            <HEREMap
              apiKey={hereMaps.apiKey}
              interactive
              secure
              hidpi
              center={center}
              search={this.props.search}
              zoom={council.lat ? 12 : 5}
              setUIElement={this.setUIElement}
              truckFilter={this.props.map.liftsFilter}
              truckMarkers={this.getTruckMarkers(
                map.truckMarkers,
                map.truckFilter,
              )}
              handleClickedTruckMarker={this.handleReceiveClickedTruck}
              setClickedTruck={this.setClickedTruck}
              alertMarkers={this.getAlertMarkers(
                map.alertMarkers,
                map.alertFilter,
                map.clickedTruck ? map.clickedTruck.id : null,
              )}
              isFiltersSetToInitialValues={
                this.state.activeLiftsWasteStream === null ||
                this.props.map.alertFilter === 'all' ||
                this.props.map.truckFilter === 'all'
              }
              activityArea={map.activityArea}
              mapInteractive={this.mapInteractive}
              activeEvent={this.state.activeEvent}
              passTruckLifts={this.updateTruckLifts}
              activeDate={this.state.activeDate}
              activeDateRange={dayFromGivenDate(
                this.state.activeDate,
                this.state.isToday,
                this.props.council.localTime,
              )}
              isToday={this.state.isToday}
              localTime={
                this.props.map.providedTimeLocalCouncil
                  ? this.props.map.providedTimeLocalCouncil
                  : this.props.council.localTime
              }
              setActiveEvent={this.setActiveEvent}
              resetActiveEvent={this.resetActiveIcon}
              centerOnAddress={map.centerOnAddress}
              addressInfo={map.addressInfo}
              mapLayer={map.mapLayer}
              allLiftsForWastestream={map.allLiftsForWastestream}
              mapDataToggles={mapDataToggles}
              clickedTruck={map.clickedTruck}
              truckLifts={truckLifts}
              setAddress={this.setAddress}
              handleThumb={this.handleThumb}
              shouldFocusOnTruckId={this.state.shouldFocusOnTruckId}
              activeLiftsWasteStream={this.state.activeLiftsWasteStream}
              handleBinLiftsFilter={this.handleBinLiftsFilter}
            />
          </Sidebar.Pushable>
        </Sidebar.Pushable>
        <ImageSlideshow
          open={this.state.modal.open}
          onMount={this.handleModalOpen}
          created={this.state.modal.created}
          isFetching={this.props.map.fetchingImages}
          images={this.props.map.images}
          imageResources={this.state.modal.imageResources}
          id={this.state.modal.id}
          handleModalClose={this.handleModalClose}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    search: state.search,
    map: state.map,
    account: state.account.info.account,
    council: state.councils.active,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...searchActionCreators,
        ...mapActionCreators,
      },
      dispatch,
    ),
)(LiveCollections);
