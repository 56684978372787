import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { DateRangePicker } from 'react-dates';
import { Route } from 'react-router-dom';
import {
  Table,
  Menu,
  Accordion,
  Loader,
  Segment,
  Divider,
  Search,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import { SAddressResult } from '../../components/SearchBar/index.sc';
import * as searchActionCreators from '../../redux/modules/search';
import * as jobsActionCreators from '../../redux/modules/jobs';
import * as liveJobActionCreators from '../../redux/modules/liveJob';
import FluidContainer from '../../components/FluidContainer';
import LiveJobFilter from '../../components/liveJobFIlter';
import PageHeader from '../../components/PageHeader';
import SortButton from '../../components/SortButton';
import { IconMap } from '../../components/Icons/Map';
import RaiseTicket from '../RaiseTicket';
import { IconClear } from '../../components/Icons/Clear';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import './job.css';
import { debounce } from 'lodash';

class JobContainer extends React.Component {
  constructor(props) {
    super(props);
    if(typeof props?.jobs?.userInteraction !== 'undefined') {
      const ui = props.jobs.userInteraction;
      this.state = {
        today: moment(),
        filters: {
          status: ui.filters?.status ?? [],
          serviceType: ui.filters?.serviceType ?? [],
        },
        sortBy: ui.sortBy ?? 'LastUpdatedDate',
        sortDirection: ui.sortDirection ?? 'descending',
        startDate: ui.startDate ?? null,
        endDate: ui.endDate ?? null,
        searchTerm: ui.searchTerm ?? null,
        scrollY: ui.scrollY ?? 0,
      };
      // Save the scrollY value to a different variable as jobs need to load first
      this.memoryScrollY = this.state.scrollY;
    }
    this.recordScroll = true;

    this.resetFilters = this.resetFilters.bind(this);
    this.view = this.view.bind(this);
    this.debouncedRequestJobQuery = debounce(() => this.requestJobQuery(true, false), 1000);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.loadMoreTickets = this.loadMoreTickets.bind(this);
    this.handleReceiveClickedSearch =
      this.handleReceiveClickedSearch.bind(this);
    this.registerScrollY = this.registerScrollY.bind(this);
    this.moveToScrollY = this.moveToScrollY.bind(this);
  }

  getJobParams(fromScratch, fromReload) {
    const { jobs } = this.props;
    const territoryId = this.props.territoryId;
    const serviceType = this.state.filters.serviceType;
    const selectedStatuses = this.state.filters.status;
    const from = fromScratch ? 0 : jobs.from + jobs.limit;
    const limit = fromReload ? jobs.from + jobs.limit : 20;
    const addressId = this.state.address?.id || null;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const searchTerm = this.state.searchTerm;
    const sortBy = this.state.sortBy;
    const sortDirection = this.state.sortDirection;
    const onload = fromReload ? () => this.moveToScrollY(this.memoryScrollY) : null;

    return {
      territoryId,
      selectedStatuses,
      serviceType,
      from,
      limit,
      addressId,
      startDate,
      endDate,
      searchTerm,
      sortBy,
      sortDirection,
      onload,
    };
  }

  requestJobQuery(fromScratch, fromReload) {
    const {
      territoryId,
      selectedStatuses,
      serviceType,
      from,
      limit,
      addressId,
      startDate,
      endDate,
      searchTerm,
      sortBy,
      sortDirection,
      onload,
    } = this.getJobParams(fromScratch, fromReload);
    this.props.requestJobs(
      territoryId,
      selectedStatuses,
      serviceType,
      from,
      limit,
      addressId,
      startDate,
      endDate,
      searchTerm,
      sortBy,
      sortDirection,
      onload,
    );
  }

  componentDidMount() {
    this.requestJobQuery(true, true);
    this.props.requestLiveJobServiceType();
    window.addEventListener('scroll', this.registerScrollY);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.registerScrollY);
  }

  registerScrollY() {
    if(this.recordScroll) {
      this.props.registerScrollY(document.documentElement.scrollTop);
    }
  }

  moveToScrollY(y) {
    window.scrollTo({
      top: y
    });
  }

  getStatusQuery(selectedStatuses) {
    let statuses = selectedStatuses;
    if (!statuses) {
      statuses = this.props.jobs.selectedStatuses;
    }
    const statusesRequired = [];
    Object.keys(statuses).forEach((key) => {
      if (statuses[key]) {
        statusesRequired.push(key);
      }
    });
    return statusesRequired.join(',');
  }

  loadMoreTickets() {
    const { jobs, requestMoreJobs } = this.props;
    const {
      territoryId,
      selectedStatuses,
      serviceType,
      from,
      limit,
      addressId,
      startDate,
      endDate,
      searchTerm,
      sortBy,
      sortDirection,
      onload,
    } = this.getJobParams(false, false);

    requestMoreJobs(
      territoryId,
      selectedStatuses,
      serviceType,
      from,
      limit,
      addressId,
      startDate,
      endDate,
      searchTerm,
      sortBy,
      sortDirection,
      onload,
    );
  }

  handleSearchChange(e, { value }) {
    this.setState(
      {
        searchTerm: value,
      },
      this.debouncedRequestJobQuery,
    );
  }

  handleClearSearch() {
    this.props.resetSearch();
    this.props.clearTickets();
    this.setState(
      {
        searchTerm: '',
      },
      () => this.requestJobQuery(true, false),
    );
  }

  view(ticket) {
    this.recordScroll = false;
    this.props.history.push(
      `/crn/${encodeURIComponent(ticket.jobId)}`,
    );
  }

  handleFilter(filterType, value) {
    const filters = this.state.filters;
    filters[filterType] = value;

    this.setState(
      {
        filters,
      },
      () => this.requestJobQuery(true, false),
    );
  }

  renderResult = ({ description }) => {
    const address = JSON.parse(description);

    return (
      <SAddressResult>
        <div className="map-icon">
          <IconMap />
        </div>
        <div className="inner">
          <span className="street">
            {address.streetNumber} {address.streetName},
          </span>{' '}
          <span className="suburb">{address.suburb.toLowerCase()}</span>
        </div>
      </SAddressResult>
    );
  };

  setActiveDate(date) {
    this.setState(
      {
        startDate: date.startDate,
        endDate: date.endDate,
      },
      () => this.requestJobQuery(true, false),
    );
  }

  getFilterMenus() {
    const jobsFilterMenus = {
      ...this.props.liveJob.jobsFilterMenus,
    };
    if(
      !!jobsFilterMenus.serviceType
      && !Array.isArray(jobsFilterMenus?.serviceType?.options)
      && !!this.state.filters.serviceType
    ) {
      Object.keys(jobsFilterMenus.serviceType.options).forEach(key => {
        const option = jobsFilterMenus.serviceType.options[key];
        option.isActive = this.state.filters.serviceType.indexOf(option.value) !== -1;
      });
    }
    if(
      !!jobsFilterMenus.status
      && !Array.isArray(jobsFilterMenus.status.options)
      && !!this.state.filters.status
    ) {
      Object.keys(jobsFilterMenus.status.options).forEach(key => {
        const option = jobsFilterMenus.status.options[key];
        option.isActive = this.state.filters.status.indexOf(option.value) !== -1;
      });
    }
    return jobsFilterMenus;
  }

  setIsFocusOnSearch = (isFocusOnSearch) => {
    this.setState((prevState) => ({
      ...prevState,
      isFocusOnSearch,
    }));
  };

  handleReceiveClickedSearch(address) {
    this.setState({
      address,
    });
  }

  resetFilters() {
    this.setState(
      {
        filters: [],
      },
      () => this.requestJobQuery(true, false),
    );
  }

  highlightText(text, searchedText) {
    if (searchedText) {
      return (
        <div
          className="highlight"
          dangerouslySetInnerHTML={{ __html: searchedText }}></div>
      );
    }
    return <span>{text}</span>;
  }

  getFirstQueryableDate = (): moment =>
    moment(this.state.today)
    .subtract(6, 'months')
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  isInValidDataRange = (pickedDay): boolean => {
    let compDate = pickedDay.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return moment(compDate).isBetween(
      this.getFirstQueryableDate(),
      moment(this.state.today).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      }),
      undefined,
      "[]"
    );
  }


  sort(sortBy, sortDirection) {
    this.setState(
      {
        sortBy,
        sortDirection,
      },
      () => this.requestJobQuery(true, false),
    );
  }

  render() {
    const { jobs, match } = this.props;
    const tableData = jobs.items;
    const { sortBy, sortDirection } = this.state;
    const emptyDate = moment('0001-01-01', 'YYYY-MM-DD');

    const ticketItems = tableData.map((ticket) => {
      const createdDate = moment(ticket.createdDate, 'YYYY-MM-DD');
      const completedDate = moment(ticket.completedDate, 'YYYY-MM-DD');
      const scheduledDate = moment(ticket.scheduledDate, 'YYYY-MM-DD');

      return (
        <Table.Row key={ticket.jobId} onClick={() => this.view(ticket)}>
          <Table.Cell>
            {this.highlightText(ticket.crn, ticket.crnDisplay)}
          </Table.Cell>
          <Table.Cell>
            {this.highlightText(
              ticket.workOrderNumber,
              ticket.workOrderNumberDisplay,
            )}
          </Table.Cell>
          <Table.Cell className="ticket-id">
            {this.highlightText(ticket.jobNumber, ticket.jobNumberDisplay)}
          </Table.Cell>
          <Table.Cell>{this.highlightText(ticket.serviceType)}</Table.Cell>
          <Table.Cell className="address">
            {this.highlightText(
              ticket.addressShort,
              ticket.addressShortDisplay,
            )}
          </Table.Cell>
          <Table.Cell>
            {this.highlightText(ticket.name, ticket.nameDisplay)}
          </Table.Cell>
          <Table.Cell className="status">
            <p
              className={`job-status-val job-status-val-${
                ticket.status?.indexOf('With Exceptions') !== -1
                  ? `Exception`
                  : ticket.status
              }`}>
              {this.highlightText(ticket.status)}
            </p>
            {ticket.reasonCode ? (
              <p className="job-status-val ">({ticket.reasonCode})</p>
            ) : null}
          </Table.Cell>
          <Table.Cell className="created">
          {ticket.createdDate && !createdDate.isSame(emptyDate) ? (
              <div>
                <strong><p>{moment(ticket.createdDate).parseZone().format('DD/MM/YY')}</p></strong>
                <p>{moment(ticket.createdDate).parseZone().format('h:mm a')}</p>
              </div>
            ) : (
              'Not Created'
            )}
          </Table.Cell>
          <Table.Cell className="created">
          {ticket.scheduledDate && !scheduledDate.isSame(emptyDate) ? (
              <div>
                <strong><p>{moment(ticket.scheduledDate).parseZone().format('DD/MM/YY')}</p></strong>
                <p>{moment(ticket.scheduledDate).parseZone().format('h:mm a')}</p>
              </div>
            ) : (
              'Not Assigned'
            )}
          </Table.Cell>
          <Table.Cell className="lastUpdated">
            {ticket.completedDate && !completedDate.isSame(emptyDate) ? (
              <div>
                <strong><p>{moment(ticket.completedDate).parseZone().format('DD/MM/YY')}</p></strong>
                <p>{moment(ticket.completedDate).parseZone().format('h:mm a')}</p>
              </div>
            ) : (
              'Not Completed'
            )}
          </Table.Cell>
          <Table.Cell className="lastUpdated">
            {ticket.lastUpdatedDate ? (
              <div>
                <strong><p>{moment(ticket.lastUpdatedDate).parseZone().format('DD/MM/YY')}</p></strong>
                <p>{moment(ticket.lastUpdatedDate).parseZone().format('h:mm a')}</p>
              </div>
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });

    const loadButton = (
      <div>
        <Divider />
        <Segment basic textAlign="center">
          <Button
            className="load-more-job-btn"
            onClick={() => this.loadMoreTickets()}
            loading={jobs.isFetchingMoreTickets}>
            Load More
          </Button>
        </Segment>
      </div>
    );

    const table = (
      <div className="table-jobs">
        <Table
          basic
          padded
          celled
          size="large"
          selectable
          className="standard tickets">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>CRN</Table.HeaderCell>
              <Table.HeaderCell>Work Order</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Service Type</Table.HeaderCell>
              <Table.HeaderCell>Address</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>
                <SortButton
                  label="Created"
                  sortBy="CreatedDate"
                  sortDirection={sortDirection}
                  action={(by, direction) => this.sort(by, direction)}
                  selected={sortBy}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <SortButton
                  label="Scheduled"
                  sortBy="ScheduledDate"
                  sortDirection={sortDirection}
                  action={(by, direction) => this.sort(by, direction)}
                  selected={sortBy}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <SortButton
                  label="Completed"
                  sortBy="CompletedDate"
                  sortDirection={sortDirection}
                  action={(by, direction) => this.sort(by, direction)}
                  selected={sortBy}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <SortButton
                  label="Last Updated"
                  sortBy="LastUpdatedDate"
                  sortDirection={sortDirection}
                  action={(by, direction) => this.sort(by, direction)}
                  selected={sortBy}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{ticketItems}</Table.Body>
        </Table>

        {/* load more */}
        {jobs.showLoadMore ? loadButton : null}
      </div>
    );
    const { search } = this.props;
    return (
      <div className="comp secondary-comp">
        <Route path={`${match.url}/raise-ticket`} component={RaiseTicket} />
        <Route
          exact
          path={match.url}
          render={() => (
            <div>
              <Helmet
                title="Jobs"
                meta={[{ name: 'Jobs', content: 'Customer Portal' }]}
              />
              <Menu secondary stackable>
                <Search
                  className={`item search-input`}
                  showNoResults={false}
                  onSearchChange={this.handleSearchChange}
                  noResultsMessage="No address found"
                  noResultsDescription="Are you sure this is correct?"
                  input={{
                    icon: 'search',
                    value: this.state.searchTerm,
                    label: (
                      <Button onClick={this.handleClearSearch}>
                        <IconClear />
                      </Button>
                    ),
                    labelPosition: 'right',
                    iconPosition: 'left',
                    placeholder: 'Search...',
                    loading: search.isFetching && search.value.length > 2,
                  }}
                />
                <div className="date-range-picker-wrapper">
                  <button>
                    <img src="/static/media/calendar.f1043932.svg" />
                  </button>

                  <DateRangePicker
                    startDate={this.state.startDate}
                    startDateId="startDateId"
                    showClearDates={true}
                    readOnly={true}
                    minimumNights={0}
                    displayFormat="DD/MM/YYYY"
                    endDate={this.state.endDate}
                    isOutsideRange={(pickedDay) =>
                      !this.isInValidDataRange(pickedDay)
                    }
                    onFocusChange={(input) =>
                      this.setState({
                        focusInput: input,
                      })
                    }
                    //onClose={(date) => this.setActiveDate(date)}
                    onDatesChange={(date) => this.setActiveDate(date)}
                    endDateId={'endDate'}
                    providedTimeLocalCouncil={this.props.council.localTime}
                    focusedInput={this.state.focusInput}
                  />
                </div>
                <Accordion className="menu-accordion tabletBreakpoint menu right">
                  <Accordion.Title></Accordion.Title>
                  <Accordion.Content>
                    <Menu.Menu position="right">
                      <LiveJobFilter
                        containerStyle={{
                          maxHeight: 300,
                          overflow: 'hidden',
                          overflowY: 'scroll',
                        }}
                        resetFilters={this.resetFilters}
                        buttonText={'Filters'}
                        handleFilter={this.handleFilter}
                        filterMenus={this.getFilterMenus()}
                        activeDate={this.state.activeDate}
                      />
                    </Menu.Menu>
                  </Accordion.Content>
                </Accordion>
              </Menu>

              <FluidContainer fluid>
                <PageHeader title="Jobs" />

                {!jobs.isFetchingWorOrderJobs ? (
                  table
                ) : (
                  <Loader
                    active={jobs.isFetching}
                    size="massive"
                    inline="centered"
                  />
                )}

                {!tableData.length && !jobs.isFetching ? (
                  <p
                    style={{
                      color: '#424852',
                      textAlign: 'center',
                      fontSize: '16px',
                    }}>
                    No results
                  </p>
                ) : null}
              </FluidContainer>
            </div>
          )}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    jobs: state.jobs,
    search: state.search,
    liveJob: state.liveJob,
    territoryId: state.councils.active.territoryId,
    council: state.councils.active,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...searchActionCreators,
        ...jobsActionCreators,
        ...liveJobActionCreators,
      },
      dispatch,
    ),
)(JobContainer);
