import { getDotColor } from './wasteStreamColour';
import general from '../assets/images/trucks/general.svg';
import green from '../assets/images/trucks/green.svg';
import recycle from '../assets/images/trucks/recycle.svg';
import hard from '../assets/images/trucks/hard.svg';
import glass from '../assets/images/trucks/glass.svg';
import organics from '../assets/images/trucks/organics.svg';
import street from '../assets/images/trucks/street.svg';
import other from '../assets/images/trucks/other.svg';

import contaminated from '../assets/images/alerts/contaminated.svg';
import notPresented from '../assets/images/alerts/not_presented.svg';
import binDamaged from '../assets/images/alerts/bin_damaged.svg';
import binNotEmptied from '../assets/images/alerts/bin_not_emptied.svg';
import otherAlert from '../assets/images/alerts/other.svg';

// waste streams
export const wasteStreams = {
  all: {
    value: 'all',
    icon: { name: 'unordered list', flipped: 'horizontally' },
    text: 'All Trucks',
    isActive: true,
  },
  general: {
    value: 'Refuse',
    image: { src: general },
    text: 'Refuse',
    isActive: false,
  },
  recycle: {
    value: 'Recycle',
    image: { src: recycle },
    text: 'Recycle',
    isActive: false,
  },
  green: {
    value: 'Greenwaste',
    image: { src: green },
    text: 'Green Waste',
    isActive: false,
  },
  hard: {
    value: 'Hardwaste',
    image: { src: hard },
    text: 'Hard Waste',
    isActive: false,
  },
  glass: {
    value: 'Glass',
    image: { src: glass },
    text: 'Glass',
    isActive: false,
  },
  organics: {
    value: 'Organics',
    image: { src: organics },
    text: 'Organics',
    isActive: false,
  },
  street: {
    value: 'Street Litter',
    image: { src: street },
    text: 'Street Litter',
    isActive: false,
  },
  other: {
    value: 'Other',
    image: { src: other },
    text: 'Other',
    isActive: false,
  },
};

export const lifts = {
  general: {
    value: 'Refuse',
    dot: { color: getDotColor('general') },
    text: 'Refuse',
    isActive: false,
  },
  recycle: {
    value: 'Recycle',
    dot: { color: getDotColor('recycle') },
    text: 'Recycle',
    isActive: false,
  },
  green: {
    value: 'Greenwaste',
    dot: { color: getDotColor('green') },
    text: 'Green Waste',
    isActive: false,
  },
  hard: {
    value: 'Hardwaste',
    dot: { color: getDotColor('hard') },
    text: 'Hard Waste',
    isActive: false,
  },
  glass: {
    value: 'Glass',
    dot: { color: getDotColor('glass') },
    text: 'Glass',
    isActive: false,
  },
  organics: {
    value: 'Organics',
    dot: { color: getDotColor('organics') },
    text: 'Organics',
    isActive: false,
  },
  street: {
    value: 'Street Litter',
    dot: { color: getDotColor('street') },
    text: 'Street Litter',
    isActive: false,
  },
  other: {
    value: 'Other',
    dot: { color: getDotColor('other') },
    text: 'Other',
    isActive: false,
  },
};

export const alertTypes = {
  all: {
    value: 'all',
    icon: { name: 'unordered list' },
    text: 'All Alerts',
    isActive: true,
  },
  contaminated: {
    value: 'Contaminated',
    image: { src: contaminated },
    text: 'Contaminated',
    isActive: false,
  },
  notPresented: {
    value: 'Not Presented',
    image: { src: notPresented },
    text: 'Not Presented',
    isActive: false,
  },
  binDamaged: {
    value: 'Bin Damaged',
    image: { src: binDamaged },
    text: 'Bin Damaged',
    isActive: false,
  },
  binNotEmptied: {
    value: 'Bin Not Emptied',
    image: { src: binNotEmptied },
    text: 'Bin Not Emptied',
    isActive: false,
  },
  other: {
    value: 'Other',
    image: { src: otherAlert },
    text: 'Other',
    isActive: false,
  },
};

export function getLiveJobStatusFilters() {
  let liveJobStatusFilters = {
    all: {
      value: 'all',
      icon: { name: 'unordered list' },
      text: 'Select All',
      isActive: true,
    },
    assignedJobs: {
      value: 'Assigned',
      text: 'Assigned Jobs',
      isActive: false,
    },
    completedJobs: {
      value: 'Completed',
      text: 'Completed Jobs',
      isActive: false,
    },
    jobsWithExceptions: {
      value: 'Exception',
      text: 'Jobs with exceptions',
      isActive: false,
    }
  };

  return liveJobStatusFilters;
}

export function getJobsTableFilters() {

  const jobsTableFilters = {
    all: { ...getLiveJobStatusFilters().all },
    unassigned: {
      value: 'Unassigned',
      text: 'Unassigned Jobs',
      isActive: false,
    },
    assignedJobs: { ...getLiveJobStatusFilters().assignedJobs },
    completedJobs: { ...getLiveJobStatusFilters().completedJobs },
    jobsWithExceptions: { ...getLiveJobStatusFilters().jobsWithExceptions },
  }

  return jobsTableFilters;
};


export const constructServiceTypeFilters = (serviceTypes) => {
  let filter = {
    all: {
      value: 'all',
      icon: { name: 'unordered list' },
      text: 'Select All',
      isActive: true,
    },
  };

  serviceTypes.forEach((type) => {
    filter[type] = {
      value: type,
      text: type,
      isActive: type.isActive,
    };
  });

  return filter;
};
