import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Header, Container, Segment } from 'semantic-ui-react';
import * as councilActionCreators from '../../redux/modules/councils';
import * as liveJobActionCreators from '../../redux/modules/liveJob';
import * as searchActionCreators from '../../redux/modules/search';
import * as jobsActionCreators from '../../redux/modules/jobs';
import FluidContainer from '../../components/FluidContainer';
import PageHeader from '../../components/PageHeader';
import {resetJobsPage} from "../../redux/modules/jobs";

const CenterWithFlex = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectButton = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  float: left;
  margin-right: 30px;

  > div {
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 250px;
    width: 250px;
    background-color: white;
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    padding: 20%;
    &:hover {
      transform: scale(1.05);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  > p {
    text-align: center;
    margin-top: 15px;
  }
`;
class CouncilSelectContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.selectCouncil = this.selectCouncil.bind(this);
  }

  props: {
    councils: Object,
  };

  selectCouncil(council) {
    this.props.selectCouncil(council);
    this.props.resetUserInteraction();
    this.props.resetLiveJobsSearch();
    this.props.resetJobsPage();
  }

  render() {
    const { councils } = this.props;

    return (
      <div className="comp">
        <FluidContainer fluid>
          <Helmet
            title="Council Select"
            meta={[{ name: 'Council Select', content: 'Customer Portal' }]}
          />
          <PageHeader title="Council Select" className="alerts-header" />
          <div className="row">
            {councils.available.map((council) => (
              <SelectButton key={council.territoryId}>
                <div onClick={() => this.selectCouncil(council)}>
                  {council.lgaImage && (
                    <img
                      src={`https://cleanaviewstaticprd.blob.core.windows.net/logos/${council.councilName}.png`}
                      alt={council.lga}
                    />
                  )}

                  <p>{council.councilName}</p>
                </div>
              </SelectButton>
            ))}
          </div>
        </FluidContainer>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    councils: state.councils,
    liveJob: state.liveJob,
    search: state.search,
  }),
  (dispatch) => bindActionCreators(
    {
      ...councilActionCreators,
      ...liveJobActionCreators,
      ...searchActionCreators,
      ...jobsActionCreators,
    }
    , dispatch),
)(CouncilSelectContainer);
