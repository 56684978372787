// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import PickSingleDate from '../../components/PickSingleDate';
import {
  Accordion,
  Button,
  Table,
  Image,
  Modal,
  Label,
  Icon,
  Menu,
  Select,
  Search,
  Loader,
  Segment,
  Divider,
} from 'semantic-ui-react';
import {
  reportedMinutes,
  toSnake,
  capitalized,
  setImageSrc,
  timeCreated,
} from '../../common/helpers';
import FluidContainer from '../../components/FluidContainer';
import PageHeader from '../../components/PageHeader';
import * as alertActionCreators from '../../redux/modules/alerts';
import * as searchActionCreators from '../../redux/modules/search';
import { alertTypes, getWasteStream } from '../../common/options';
import Close from '../../common/images/general/close_round.svg';
import Remove from '../../common/images/messages/remove.svg';
import { IconMap } from '../../components/Icons/Map';
import { SAddressResult } from '../../components/SearchBar/index.sc';
import { getCurrentYearDateRangeForQuery, dayFromGivenDate } from '../../common/timeOffsetHelper';
import './alert.css';

class AlertsContainer extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
    this.loadMoreAlerts = this.loadMoreAlerts.bind(this);
    this.handleSearchResultSelect = this.handleSearchResultSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = { modalOpen: false };
  }

  componentDidMount() {
    const {
      location,
      search,
      alerts: { addressSearchId, reason, from, limit, items },
      council: { territoryId },
    } = this.props;

    const fromMap =
      location.state !== undefined &&
      location.state.fromMap &&
      search.result.id !== null;

    const date = this.getOffsetForQuery();
    this.setState({
      ...this.state,
      activeDate: date.dateFrom,
      isToday: true
    }, () => {

      if (!items.length && !fromMap) {
        this.props.resetSearch();
        this.props.requestAlerts({
          ...this.getOffsetForQuery(),
          ...{
            territoryId,
            addressSearchId,
            reason,
            from,
            limit,
          },
        });
      }

      // came from map sidebar
      if (fromMap) {
        this.props.requestAlerts({
          ...this.getOffsetForQuery(),
          ...{
            territoryId,
            addressSearchId: search.result.id,
            reason,
            from,
            limit,
          },
        });

        // reset location state
        location.state.fromMap = false;
      }

    });

  }
  getOffsetForQuery = () =>
    getCurrentYearDateRangeForQuery(this.props.council.localTime);
  /**
   *  Hits the address endpoint as the user types in the search input. Handle
   *  the case when the user clears the search input completely.
   */
  handleSearchChange(e, { value }) {
    this.props.searchValueChanged(value, this.props.territoryId);
    if (!value) {
      this.handleClearSearch();
    }
  }

  /**
   *  Clear search results on the state tree and hit the endpoint to refresh the
   *  bin events list.
   */
  handleClearSearch() {
    this.props.resetSearch();
    this.props.resetItems();
    this.props.location.state = undefined;
    this.props.requestAlerts({
      ...this.getOffsetForQuery(),
      ...{
        territoryId: this.props.council.territoryId,
        addressSearchId: null,
        reason: '',
        from: 0,
        limit: 20,
        dateFrom: this.state.activeDate,
        dateTo: this.state.activeDate,
      },
    });
  }

  /* Hits the filtering endpoint when the user selects a search result */
  handleSearchResultSelect(e, { result }) {
    const { reason, limit } = this.props.alerts;
    const parsedResult = JSON.parse(result.description);

    this.props.setSearchValue(parsedResult);
    this.props.resetItems();

    this.props.requestAlerts({
      ...this.getOffsetForQuery(),
      ...{
        territoryId: this.props.council.territoryId,
        addressSearchId: parsedResult.id,
        reason,
        from: 0,
        limit,
        dateFrom: this.state.activeDate,
        dateTo: this.state.activeDate,
      },
    });
  }

  /* Hits the filtering endpoint when the user selects a filter */
  handleFilterValueChange(event, { value }) {
    const { addressSearchId, limit } = this.props.alerts;

    this.props.resetItems();
    this.props.requestAlerts({
      ...this.getOffsetForQuery(),
      ...{
        territoryId: this.props.council.territoryId,
        addressSearchId,
        reason: value,
        from: 0,
        limit,
        dateFrom: this.state.activeDate,
        dateTo: this.state.activeDate,
      },
    });
  }

  /* Hits the filtering endpoint to load another page of results */
  loadMoreAlerts() {
    const { addressSearchId, reason, from, limit } = this.props.alerts;
    this.props.requestMoreAlerts({
      ...this.getOffsetForQuery(),
      ...{
        territoryId: this.props.council.territoryId,
        addressSearchId,
        reason,
        from: from + limit,
        dateFrom: this.state.activeDate,
        dateTo: this.state.activeDate,
        limit,
      },
    });
  }

  /* Hits the endpoint to get an alert image, or loads it off state if possible */
  changeToImage(binEvent, imageId) {
    const { binIdImg } = this.props.alerts;
    if (binIdImg[binEvent][imageId] === undefined) {
      this.props.requestBinImages(binEvent, imageId);
    } else {
      this.props.selectImage(binEvent, imageId);
    }
  }

  handleModalOpen(internalId) {
    const { binIdImg } = this.props.alerts;
    if (
      binIdImg[internalId] !== undefined &&
      binIdImg[internalId].imageId.length
    ) {
      this.changeToImage(internalId, 0);
      this.setState({
        modalOpen: {
          [internalId]: true,
        },
      });
    }
  }

  handleModalClose(internalId) {
    this.props.resetDisplayedImage();
    this.setState({
      modalOpen: {
        [internalId]: false,
      },
    });
  }

  props: {
    requestAlerts: Function,
    requestMoreAlerts: Function,
    requestBinImages: Function,
    selectImage: Function,
    resetSearch: Function,
    resetDisplayedImage: Function,
    setSearchValue: Function,
    resetItems: Function,
    searchValueChanged: Function,
    alerts: Object,
    location: Object,
    search: Object,
    account: Object,
    council: Object,
  };

  /* Returns a search result line */
  renderResult = ({ description }) => {
    const address = JSON.parse(description);

    return (
      <SAddressResult>
        <div className="map-icon">
          <IconMap />
        </div>
        <div className="inner">
          <span className="street">
            {address.streetNumber} {address.streetName},
          </span>{' '}
          <span className="suburb">{address.suburb.toLowerCase()}</span>
        </div>
      </SAddressResult>
    );
  };

  setActiveDate = (activeDate, isToday) => {
    const {
      alerts: { addressSearchId, reason, from, limit, items },
      council: { territoryId },
    } = this.props;

    const dateRange = dayFromGivenDate(
      activeDate,
      isToday,
      this.props.council.localTime,
    );

    this.setState({ ...this.state, activeDate: dateRange.dateFrom, isToday }, () => {
      this.props.requestAlerts({
        resetData: true,
        ...this.getOffsetForQuery(),
        ...{
          territoryId,
          addressSearchId,
          reason,
          from,
          limit,
          dateFrom: dateRange.dateFrom,
          dateTo: dateRange.dateTo
        },
      });
    });
  }

  render() {
    const { location, search, alerts } = this.props;
    const alertItems = alerts.items.map((alert, index) => {
      const wasteStream = getWasteStream(capitalized(alert.wasteStream));

      return (
        <Table.Row
          key={`${alert.internalId}-${index}`}
          onClick={() => this.handleModalOpen(alert.internalId)}>
          <Table.Cell className="thumb-cell">
            <div
              style={{ position: 'relative', width: '80px' }}
              role="presentation">
              <Image
                className="thumb"
                src={setImageSrc(
                  alerts.binIdImg[alert.internalId] &&
                    alerts.binIdImg[alert.internalId].thumbnail
                    ? alerts.binIdImg[alert.internalId].thumbnail
                    : null,
                )}
                size="tiny"
                alt="Bin"
              />
              {alerts.binIdImg[alert.internalId] !== undefined &&
                alerts.binIdImg[alert.internalId].imageId.length !== 0 && (
                  <Icon className="icon-search-plus" />
                )}
            </div>
            <Modal
              open={this.state.modalOpen[alert.internalId]}
              onClose={() => this.handleModalClose(alert.internalId)}
              basic
              className="image-modal"
              size="small">
              <Modal.Header>
                <Label>
                  <Icon name="clock" />
                  {timeCreated(alert.created)}
                </Label>
                <Image
                  src={Remove}
                  className="close icon"
                  alt="close"
                  onClick={() => {
                    this.handleModalClose(alert.internalId);
                  }}
                />
              </Modal.Header>
              <Modal.Content>
                <div className="bin-alert-lightbox">
                  <div className="btn-wrapper">
                    <button
                      className="previous-btn"
                      onClick={() =>
                        this.changeToImage(
                          alert.internalId,
                          alerts.selectedImageId - 1,
                        )
                      }
                      disabled={alerts.selectedImageId === 0}>
                      <Icon name="angle left" size="huge" />
                    </button>
                  </div>
                  <div className="image-wrapper">
                    {alerts.isFetchingAlertImage ? (
                      <div>                                                
                      <Loader active size="big" inline="centered" />
                      </div>
                    ) : (
                      <Image
                        src={setImageSrc(alerts.selectedImageSrc)}
                        alt="bin"
                      />
                    )}
                  </div>
                  <div className="btn-wrapper">
                    <button
                      className="next-btn"
                      onClick={() =>
                        this.changeToImage(
                          alert.internalId,
                          alerts.selectedImageId + 1,
                        )
                      }
                      disabled={
                        alerts.binIdImg[alert.internalId] !== undefined &&
                        alerts.selectedImageId ===
                        alerts.binIdImg[alert.internalId].imageId.length - 1
                      }>
                      <Icon name="angle right" size="huge" />
                    </button>
                  </div>
                </div>
              </Modal.Content>
            </Modal>
          </Table.Cell>
          <Table.Cell className="address">
            <span role="presentation">
              {alert.address.streetNumber} {alert.address.streetName},{' '}
              <br className="responsive-break" /> {alert.address.suburb},{' '}
              {alert.address.postCode}
            </span>
            <div className="show-on-mobile">
              <Icon className={`icon-warning ${toSnake(alert.reason)}`} />
              <span>{capitalized(alert.reason)}</span>
            </div>
          </Table.Cell>
          <Table.Cell className="streetNumberDriverVerification">
              {alert.address.streetNumberDriverVerification}
          </Table.Cell>
          <Table.Cell className="type show-on-desktop">
            <Icon className={`icon-warning ${toSnake(alert.reason)}`} />
            <span>{alert.reason ? capitalized(alert.reason) : 'Unknown'}</span>
          </Table.Cell>
          <Table.Cell className="type show-on-desktop">
            <span>
              {alert.secondaryReason
                ? capitalized(alert.secondaryReason)
                : 'Unknown'}
            </span>
          </Table.Cell>
          <Table.Cell className="waste-stream">
            <Image
              src={wasteStream.image.src}
              className="truck"
              alt="Waste stream icon"
              verticalAlign="middle"
            />
            <span>{wasteStream ? wasteStream.text : 'Unknown'}</span>
          </Table.Cell>
          <Table.Cell className="time">
            {reportedMinutes(alert.created)}
          </Table.Cell>
        </Table.Row>
      );
    });

    const table = (
      <Table
        basic
        padded
        selectable
        celled
        size="large"
        className="standard alerts">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Photo</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Confirmed House Number</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Reason Code</Table.HeaderCell>
            <Table.HeaderCell>Waste Stream</Table.HeaderCell>
            <Table.HeaderCell>Reported</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{alertItems}</Table.Body>
      </Table>
    );

    const loadButton = (
      <div>
        <Divider />
        <Segment basic textAlign="center">
          <Button
            basic
            color="blue"
            size="massive"
            className="load-more-btn"
            onClick={this.loadMoreAlerts}
            loading={alerts.isFetchingMoreItems}>
            Load More
          </Button>
        </Segment>
      </div>
    );

    // Transform search items to match the format Semantic UI React is expecting.
    const searchAddresses = search.items.addresses.slice(0, 8).map((s) => ({
      description: JSON.stringify(s),
      key: s.id,
      title: 'address',
    }));

    return (
      <div className="comp secondary-comp">
        <Helmet
          title="Alerts"
          meta={[{ name: 'Alerts', content: 'Customer Portal' }]}
        />
        <Menu secondary stackable>
          <Menu.Item>
            {/* Removed alerts address search per request from Garry in CP25-6
            <Search
              className={`item search-input ${search.value ? 'shown' : 'hidden'}`}
              onResultSelect={this.handleSearchResultSelect}
              showNoResults={false}
              onSearchChange={this.handleSearchChange}
              noResultsMessage="No address found"
              noResultsDescription="Are you sure this is correct?"
              results={searchAddresses}
              value={search.value}
              resultRenderer={this.renderResult}
              input={{
                icon: 'search',
                label: (
                  <Button onClick={() => this.handleClearSearch()}>
                    <Image src={Close} alt="close" />
                  </Button>
                ),
                labelPosition: 'right',
                iconPosition: 'left',
                placeholder: 'Search Address...',
                loading: search.isFetching,
              }}
            />
            */}
            <PickSingleDate
              setActiveDate={this.setActiveDate}
              providedTimeLocalCouncil={this.props.council.localTime}
            />
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item>
              <Accordion className="menu-accordion computerBreakpoint menu right">
                <Accordion.Title>
                  <Icon className="icon-mobile-toggle" />
                </Accordion.Title>
                <Accordion.Content>
                  <Menu.Menu position="right">
                    {/* Removed alerts address search per request from Garry in CP25-6
                    {alerts.addressSearchId !== null ||
                      (location.state !== undefined && location.state.fromMap) ? (
                      <Link
                        to={{
                          pathname: '/live-collections',
                          state: { fromList: true },
                        }}
                        className="link item view-map">
                        View on Map
                        <Icon name="map outline" style={{ marginLeft: '15px' }} />
                      </Link>
                    ) : null}
                    */}
                    <Select
                      placeholder="All Alerts"
                      onChange={this.handleFilterValueChange}
                      options={alertTypes}
                      icon="angle down"
                      className="user-dropdown"
                    />
                  </Menu.Menu>
                </Accordion.Content>
              </Accordion>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <FluidContainer fluid>
          <PageHeader title="Alerts" className="alerts-header" />

          {/* show table or loader */}
          {alerts.items.length && !alerts.isFetching ? (
            table
          ) : (
            <Loader
              active={alerts.isFetching}
              size="massive"
              inline="centered"
              style={{ marginTop: '50px' }}
            />
          )}

          {/* no results */}
          {!alerts.items.length && !alerts.isFetching ? (
            <p
              style={{
                color: '#424852',
                textAlign: 'center',
                fontSize: '16px',
              }}>
              No results
            </p>
          ) : null}
        </FluidContainer>

        {/* load more */}
        {!alerts.isFetching && alerts.items.length && alerts.items.length % alerts.limit === 0
          ? loadButton
          : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    alerts: state.alerts,
    search: state.search,
    account: state.account.info.account,
    council: state.councils.active,
    territoryId: state.councils.active.territoryId,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...alertActionCreators,
        ...searchActionCreators,
      },
      dispatch,
    ),
)(AlertsContainer);

